import React from "react"

import UseInfiniteScrollFetchFree from "./UseInfiniteScrollFetchFree"
import ExtendedNav from "./ExtendedNav"

function Form() {

  return (

    <div>
    <ExtendedNav />
    <UseInfiniteScrollFetchFree database={"freeform"}/>
    </div>

  )


}

export default Form
