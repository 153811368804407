import React from "react"
import { useState, useEffect } from 'react';
import {withRouter} from 'react-router';
import { db } from '../services/firebase';

import Subscribe from "./Subscribe"
import ArtistInResInit from "./ArtistInResInit"
import ArtistInResFollow from "./ArtistInResFollow"
import IconBarSocial from "./IconBarSocial"
import DateModalCalcVolume from "./DateModalCalcVolume"

import ExtendedNav from "./ExtendedNav"
import WaitDisplay from "./WaitDisplay"
import Paradise from "./Paradise"
import FadeInSection from "./FadeInSection"

function ArtistInRes(props){

  //const [written, setWritten] = useState(null)
  const [written, setWritten] = useState([])
  const [main, setMain] = useState(true)

  useEffect(() => {
        if(props.cover){
          const subscriber = db
          .collection('covers')
          .where("coverlocation", "==", props.location.pathname)
          .limit(2)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setWritten(data)
          });
        } else if (props.searchArticles){
          const subscriber = db
          .collection('art')
          .where("author", "==", props.location.pathname.replace('/', ''))
          .limit(2)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setWritten(data)
          });
        }

        if(props.location.pathname == "/"){
          setMain(true)
        } else {
          setMain(false)
        }

}, [props.searchArticles, props.cover, props.location.pathname]);

       return (

        <div id="test">
            {/*<Paradise />*/}
            <ExtendedNav/>
            {/*
            <section>
            <p className="timing">ARTIST IN RESIDENCE</p>
            <hr/>
            <DateModalCalcVolume/>
            </section>
            */}
            <ArtistInResInit searchArticles = {props.searchArticles} cover={props.cover} main={main}/>
            <ArtistInResFollow written={written}/>
            <section>
            </section>

        </div>

      )

}

export default withRouter(ArtistInRes)
