import React from 'react'
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';

import UseInfiniteScroll from "./UseInfiniteScroll"
import GridVerticaFreeForm from "./GridVerticaFreeForm"
import Spinner from "./Spinner"

function UseInfiniteScrollFetchFree(props) {

    const [articles, setArticles] = useState([]);
    const [isFetching, setIsFetching] = UseInfiniteScroll(fetchMoreListItems);

    const [start, setStart] = useState(null)
    const [limit, setLimit] = useState(3)

    const [initial, setInitial] = useState(null)

    function fetchMoreListItems() {
    if(start){
      setInitial(null)
              setTimeout(() => {
                if(start){
                const subscriber = db
                  .collection(props.database)
                  .orderBy('timestamp', 'desc')
                  .startAt(start)
                  .limit(limit)
                  .get()
                  .then(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    const [a, b, c] = data;
                    if(a && b){
                        if(c){
                          setStart(c.timestamp)
                        } else {
                          setStart(null)
                        }
                    } else if (a) {
                    setStart(null)
                    } else {
                    setStart(null)
                    }
                    });
              }
                setIsFetching(false);
              }, 2000);
      } else if (initial) {
              setTimeout(() => {
                setStart(initial)
                setIsFetching(false);
              }, 2000);
      }
    }

    useEffect(() => {
        if(start){
              setTimeout(() => {
                if(start){
                const subscriber = db
                  .collection(props.database)
                  .orderBy('timestamp', 'desc')
                  .startAt(start)
                  .limit(3)
                  .get()
                  .then(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    const [a, b, c] = data;
                    if(a && b){
                    const display = [a,b]
                    var bool = articles.some(item => item.title === a.title)
                        if(!bool){
                        setArticles(prevState => [...prevState, ...display]) 
                        }  
                    } else if (a) {
                    var bool = articles.some(item => item.title === a.title)
                    const display = [a]
                        if(!bool){
                        setArticles(prevState => [...prevState, ...display])
                        }
                    }
                    });
              }
              }, 1);
        } else if (initial){
              setTimeout(() => {
                if(initial){
                const subscriber = db
                  .collection(props.database)
                  .orderBy('timestamp', 'desc')
                  .startAt(initial)
                  .limit(limit)
                  .get()
                  .then(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    const [a, b, c] = data;
                    if(a && b){
                    const display = [a,b]
                    setArticles(prevState => [...prevState, ...display])
                    } else if (a) {
                    const display = [a]
                    setArticles(prevState => [...prevState, ...display])
                    }
                    });
              }
              }, 1);
        }
    }, [start]);

    useEffect(() => {
      const subscriber = db
        .collection(props.database)
        .orderBy('timestamp', 'desc')
        .limit(4)
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          const [a, b, c, d] = data;
          if(a && b && c){
            const display = [a, b, c]
            setArticles(display)
            if(d){
              setInitial(d.timestamp)
            }
          } else if(a && b){
            const display = [a, b]
            setArticles(display)
            if(c){
              setInitial(c.timestamp)
            }
          } else if (a) {
            const display = [a]
            setArticles(display)
          }
        });
    }, [props.database]);

    return(
        <section>
            <GridVerticaFreeForm articles={articles}/>
            {isFetching && <Spinner/>}
        </section>
      )


}

export default UseInfiniteScrollFetchFree
