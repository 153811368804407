import React from "react"
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"

import FadeInSection from "./FadeInSection"
import DateModalCalc from "./DateModalCalc"

import LinkExternal from "./LinkExternal"

import IconBarSocial from "./IconBarSocial"

function GridVerticaGate(props){

    const size = UseWindowSize()

      return (
        <div>
          <FadeInSection>
          <hr/>
          <p className="timing">{props.article.topic.toUpperCase()}</p>
          <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>
            <LinkExternal article={props.article} />
          </div>
          <img src={props.article.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={props.article.alt}
                />
          </div>
          <Link to={props.article.author}>
          <h1 style={{textAlign:"right"}}>{props.article.author}</h1>
          </Link>
          <div>
            <hr/>
            <p className="timing">ARTICLE SOUL</p>
            <p>{props.article.soul}</p>
          {/*props.cover &&
            <hr/>
          */}
          </div>
          </FadeInSection>
          <FadeInSection>
          {props.article.topic2 &&
          <p className="timing">{props.article.topic2.toUpperCase()}</p>
          }
          {props.article.paras && props.article.paras.map(para => (
          <p>
          {para}
          </p>
          ))}
          {/*props.article.author2 &&
          <Link to={props.article.author2}>
          <h1 style={{textAlign:"right"}}>{props.article.author2}</h1>
          </Link>
          */}
          <DateModalCalc article={props.article}/>
          {/*<section style={size.width > 1000 ? {} : {maxWidth: "60%"}}>*/}
          {/*<section>*/}

          {/*</section>*/}
          {/*<p style={{color:"grey", fontSize:".8em"}}>26 SECONDS AGO</p>*/}
          {props.article.cover &&
              <IconBarSocial />
          }
          </FadeInSection>
        </div>
      )

}

export default GridVerticaGate
