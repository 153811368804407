import React from "react"

import UseWindowSize from "./UseWindowSize"
import {Link} from "react-router-dom";

function ArtistInResGridVertica(props){

    const size = UseWindowSize()

    //                <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>

            return (
              <article className="post">
              {props.cover && props.articles[0].macro &&
              <div>
              <p className="timing">{props.articles[0].macro.toUpperCase()}</p>
              <hr />
              <p className="timingext">{props.articles[0].micro.toUpperCase()}</p>
              </div>
              }
              {props.articles.map(article => (
                <Link to={article.location}>
                <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
                <div className="sidebarx" style={{zIndex: 950}}>

                <h1>
                <span className="over">{article.title}</span>
                </h1>
                <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{article.content}</p>
                <h1>
                <span className="over">{article.titlex}</span>
                </h1>
                <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{article.contentx}</p>

                </div>

                <img src={article.url}
                          style={{display: "block",
                                  width: "70%",
                                  height: "70%",
                                  objectFit: "cover",
                                 zIndex: 900}}
                          alt={article.alt}
                      />

                </div>
                </Link>

              ))}
              </article>
            )

}

export default ArtistInResGridVertica
