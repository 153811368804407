import React from 'react'
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"
import DateModal from "./DateModal"
import DateModalCalc from "./DateModalCalc"

import FadeInSection from "./FadeInSection"

import IconBarSocial from "./IconBarSocial"

import Paradise from "./Paradise"

function GridVerticaGateInit(props){

    const size = UseWindowSize()

    if(props.cover){

      return (
        <div id="test">
          <FadeInSection>
          <DateModal/>
          <hr/>
          <Link to={props.article.location}>
          <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>

            <h1>
            <span className="over">{props.article.title}</span>
            </h1>
            <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{props.article.content}</p>
            <h1>
            <span className="over">{props.article.titlex}</span>
            </h1>
                <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{props.article.contentx}</p>

          </div>
          <img src={props.article.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={props.article.alt}
                />
          </div>
          </Link>
          <Link to={props.article.author}>
          <h1 style={size.width > 1000 ? {textAlign:"right", fontSize:"1.2em"} : {textAlign:"right", fontSize:".8em"}}>@jennywest</h1>
          </Link>
          {/*
            <p style={{color:"grey", fontSize:".6em"}}>
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            </p>
          */}
          <div>
            <hr/>
            <p className="timing">ARTICLE SOUL</p>
            <p>{props.article.soul}</p>
          {/*props.cover &&
            <hr/>
          */}
          </div>
          </FadeInSection>
          <FadeInSection>
          {props.article.topic &&
          <p className="timing">{props.article.topic.toUpperCase()}</p>
          }
          {props.article.paras && props.article.paras.map(para => (
          <p>
          {para}
          </p>
          ))}
          {/*props.article.author2 &&
          <Link to={props.article.author2}>
          <h1 style={size.width > 1000 ? {textAlign:"right", fontSize:"1.2em"} : {textAlign:"right", fontSize:".8em"}}>@maryanne</h1>
          </Link>
          */}
          <DateModalCalc article={props.article}/>
          {/*<section style={size.width > 1000 ? {} : {maxWidth: "60%"}}>*/}
          {/*<section>*/}
          {/*</section>*/}
          {/*<p style={{color:"grey", fontSize:".8em"}}>26 SECONDS AGO</p>*/}
          <Paradise art={true}/>
          {props.article.soul &&
              <IconBarSocial />
          }
          </FadeInSection>
        </div>
      )

    } else {

      return (
        <div id="test">
          <FadeInSection>
          <DateModal/>
          <hr/>
          <Link to={props.article.location}>
          <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>

            <h1>
            <span className="over">{props.article.title}</span>
            </h1>
            <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{props.article.content}</p>
            <h1>
            <span className="over">{props.article.titlex}</span>
            </h1>
            <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{props.article.contentx}</p>

          </div>
          <img src={props.article.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={props.article.alt}
                />
          </div>
          </Link>
          <Link to={props.article.author}>
          <h1 style={{textAlign:"right"}}>{props.article.author}</h1>
          </Link>
          {/*
            <p style={{color:"grey", fontSize:".6em"}}>
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            </p>
          */}
          <div>
            <hr/>
            <p className="timing">ARTICLE SOUL</p>
            <p>{props.article.soul}</p>
          {/*props.cover &&
            <hr/>
          */}
          </div>
          </FadeInSection>
          <FadeInSection>
          {props.article.topic &&
          <p className="timing">{props.article.topic.toUpperCase()}</p>
          }
          {props.article.paras && props.article.paras.map(para => (
          <p>
          {para}
          </p>
          ))}
          {/*props.article.author2 &&
          <Link to={props.article.author2}>
          <h1 style={{textAlign:"right"}}>{props.article.author2}</h1>
          </Link>
          */}
          {props.article.timestamp &&
          <DateModalCalc article={props.article}/>
          }
          {/*<section style={size.width > 1000 ? {} : {maxWidth: "60%"}}>*/}
          {/*<section>*/}
          <Paradise art={true}/>
          {/*</section>*/}
          {/*<p style={{color:"grey", fontSize:".8em"}}>26 SECONDS AGO</p>*/}

              <IconBarSocial />

          </FadeInSection>
        </div>
    )

    }

}

export default GridVerticaGateInit
