import React from "react"
import { useState, useEffect } from "react"
import { auth } from '../services/firebase';
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"


import ExtendedNav from "./ExtendedNav"

function Settings(props){

  const size = UseWindowSize();

  function signOutUser() {

    auth().signOut()

  }

  const [soulLink, setSoulLink] = useState("")

  useEffect(() => {

      setSoulLink("https://www.soul.kujeera.com")

  }, []);

  function handleClick(){
    //window.location.replace('https://airtable.com/shrkXVvmTJvfhaYy5')
    window.location.href = "https://www.soul.kujeera.com";
  }

  if (!props.subbed) {

          return (
          <div>
          <ExtendedNav/>
          <div className="centicons" style={{textAlign:"center"}}>
          <section>
          <div>
          <div
            className="profile centpf">
          <Link to="/subscribe" >
          <p>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>subscribe</em></span>
          </p>
          </Link>
          <Link to={{
            pathname: '/accountdetails',
            state: {
              subbed: false
            }
          }}>
          <p>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>account</em></span>
          </p>
          </Link>
          <p>
          <span className="over pointy" onClick={handleClick} style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>soul</em></span>
          </p>
          <Link to="/about">
          <p>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>about</em></span>
          </p>
          </Link>
          </div>
          <Link to="/profile">
          {/*<span className="iconButton"><ion-icon name="ellipsis-horizontal-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:40}}></ion-icon></span>*/}
          </Link>
          </div>
          <hr/>
          <button className='btn extra double' onClick={() => signOutUser()}>Sign Out</button>
          </section>
          </div>
          </div>
        );

  } else {

        return (
        <div>
        <ExtendedNav/>
        <div className="centicons" style={{textAlign:"center"}}>
        <section>
        <div >
        <div
            className="profile centpf">
          <Link to="/subscribe" >
          <p>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>subscription</em></span>
          </p>
          </Link>
          <Link to={{
            pathname: '/accountdetails',
            state: {
              subbed: true
            }
          }}>
          <p>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>account</em></span>
          </p>
          </Link>
          <p>
          <span className="over pointy" onClick={handleClick} style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>soul</em></span>
          </p>
          <Link to="/about">
          <p>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>about</em></span>
          </p>
          </Link>
        </div>
        <Link to="/profile">
        </Link>
        </div>
        <hr/>
        <button className='btn extra double' onClick={() => signOutUser()}>Sign Out</button>
        </section>
        </div>
        </div>
      );

  }

}

export default Settings
