import React from 'react';
import { useState, useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Signup from './pages/Signup';
import Login from './pages/Login';
import Article from './pages/Article'
import ForgotPassword from './pages/ForgotPassword'
import Profile from './pages/Profile'
import AdminGate from './pages/AdminGate'
import Settings from './pages/Settings'
import GoldPage from './pages/GoldPage'
import Subscribe from './pages/Subscribe'

import AccountDetails from './pages/AccountDetails'
import About from './pages/About'
import Help from './pages/Help'
import WaitDisplay from './pages/WaitDisplay'
import Form from './pages/Form'

import ArtistInRes from './pages/ArtistInRes'

import LockedSite from './pages/LockedSite'

//import SignupEmail from './pages/SignupEmail'

import Newsletter from './pages/Newsletter'

import Careers from "./pages/Careers"

import LoginLocked from "./pages/LoginLocked"

import { auth } from './services/firebase';

function PrivateRoute({ component: Component, user, subbed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => user != null
        ? <Component {...props} user={user} subbed={subbed} />
        : <WaitDisplay Component = {<Redirect to={{ pathname: '/login', state: { from: props.location } }} />} duration = {320} />}
    />
  )
}

function PublicRoute({ component: Component, user, subbed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => user == null
        ? <Component {...props} user={user} subbed={subbed}/>
        : <Redirect to='/settings' />}
    />
  )
}

function App(props){
  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null);
  const [subbed, setSubbed] = useState(false);
  const [admin, setAdmin] = useState(false)

  // Handle user state changes
  function onAuthStateChanged(user) {
    setUser(user);
  }

  useEffect(() => {
    async function sgetStripeCustomer() {
      const customer = await getStripeCustomer();
      const subscriptions = customer.subscriptions.data
      if(subscriptions.length != 0){
        setSubbed(true)
      } else {
        scheckStudent()
      }
      if (initializing) setInitializing(false);
    }

    async function scheckStudent() {
      const response = await isStudent();
      if(response.value){
        setSubbed(true)
        console.log("user is student")
      } else {
        setSubbed(null)
      }
      if (initializing) setInitializing(false);
    }

    async function scheckAdmin() {
      const response = await isAdmin();
      if(response.value){
        setAdmin(true)
        console.log("user is admin")
      } else {
        setAdmin(null)
      }
      if (initializing) setInitializing(false);
    }

    if(user){
      sgetStripeCustomer();
      scheckAdmin()
    } else {
      setSubbed(null)
    if (initializing) setInitializing(false);
    }
  }, [user]);

  async function getStripeCustomer () {

    const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getFirestoreCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              })
      })

      const firestoreData = await firestoreResponse.json()
      var firestoreBody = JSON.parse(firestoreData.body);
      var customerId = firestoreBody.customerId;

    const stripeResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getStripeCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              customerId: customerId,
              })
      })

      const stripeData = await stripeResponse.json()
      var stripeBody = JSON.parse(stripeData.body);
      var customer = stripeBody.customer;
      return customer

  }

  async function isStudent () {

    const studentResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/isStudent', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              })
      })

      const studentData = await studentResponse.json()
      var studentBody = JSON.parse(studentData.body);
      var jsonResponse = studentBody.response;
      return jsonResponse

  }

  async function isAdmin () {

      const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/isAdmin', {
          method: 'POST',
          // Adding the order data to payload
          body: JSON.stringify({
                userId: user.uid,
                })
        })

        const firestoreData = await firestoreResponse.json()
        var firestoreBody = JSON.parse(firestoreData.body);
        var jsonResponse = firestoreBody.response;
        return jsonResponse
  }

  useEffect(() => {
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  if (initializing) return null;

  //locked site

  /*

  if(!admin){

  return (
    <Router>
      <Switch>
        <Route
            exact path="/"
            render={(props) => <WaitDisplay Component={<LockedSite {...props} user={user} subbed={subbed}/>} duration={400}/>}
            />
            <PublicRoute path="/login" user={user} subbed={subbed} component={LoginLocked}></PublicRoute>
            <PrivateRoute path="/settings" user={user} subbed={subbed} component={Settings}></PrivateRoute>
        <Route
              path="/:id"
              render={(props) => <WaitDisplay Component={<LockedSite {...props} user={user} subbed={subbed}/>} duration={400}/>}
        />
      </Switch>
    </Router>
  )

  }

  */



  return (
    <Router>
      <Switch>
        <Route
              exact path="/"
              render={(props) => <ArtistInRes {...props} user={user} subbed={subbed} />}
        />
        <Route path="/profile" component={(props) => {
            window.location.replace('/settings')
            //window.location.href = 'https://kujeera.com'+ props.location.pathname;
            return null;
        }}/>
        <PublicRoute path="/signup" user={user} subbed={subbed} component={Signup}></PublicRoute>
        {/*<PublicRoute path="/signupemail" user={user} subbed={subbed} component={SignupEmail}></PublicRoute>*/}
        <PublicRoute path="/login" user={user} subbed={subbed} component={Login}></PublicRoute>
        <PrivateRoute path="/settings" user={user} subbed={subbed} component={Settings}></PrivateRoute>
        <PrivateRoute path="/accountdetails" user={user} subbed={subbed} component={AccountDetails}></PrivateRoute>
        <Route
              path="/forgotpassword"
              render={(props) => <ForgotPassword {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/form"
              render={(props) => <Form {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/careers"
              render={(props) => <Careers {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/admin"
              render={(props) => <AdminGate {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/subscribe"
              render={(props) => <Subscribe {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/about"
              render={(props) => <About {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/support"
              render={(props) => <Help {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/help"
              render={(props) => <Help {...props} user={user} subbed={subbed} />}
        />
        <Route
              path="/art"
              render={(props) => <ArtistInRes {...props} user={user} subbed={subbed} />}
        />
        <Route path="/bill" component={(props) => {
            window.location.replace('https://www.kujeera.com/help')
            //window.location.href = 'https://kujeera.com'+ props.location.pathname;
            return null;
        }}/>
        <Route path="/soul" component={(props) => {
            window.location.replace('https://www.soul.kujeera.com')
            //window.location.href = 'https://kujeera.com'+ props.location.pathname;
            return null;
        }}/>
        <Route
              path="/:id"
              render={(props) => <Article {...props} user={user} subbed={subbed} />}
        />
      </Switch>
    </Router>
  )



}

export default App;
