import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import ArtistInResGridVertica from "./ArtistInResGridVertica"
import ArtistInResFreeFormInit from "./ArtistInResFreeFormInit"
import GoldPageEditorsNoteSubscribe from "./GoldPageEditorsNoteSubscribe"

function ArtistInResInit(props){

    const [articles, setArticles] = useState([])

    useEffect(() => {
      if(!props.searchArticles){
        const subscriber = db
          .collection('coverartists')
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setArticles(data);
          });
      } else {
        setArticles(props.searchArticles)
      }

  }, []);

          return (
              <div>
              {articles.length != 0 &&
              <div>
              <section>
              <ArtistInResGridVertica articles={articles} cover={props.cover}/>
              </section>
              <ArtistInResFreeFormInit articles={articles} main={props.main}/>
              </div>
              }
              </div>
          )

}

export default withRouter(ArtistInResInit)
