import React from "react"

import LoginSubscribeDynamic from "./LoginSubscribeDynamic"
import SubscribeGate from "./SubscribeGate"


import ExtendedNav from "./ExtendedNav"
import WaitDisplay from "./WaitDisplay"

function Subscribe(props) {

    if (!props.user) {
      return (
        <div>
        <ExtendedNav/>
        <WaitDisplay Component={<LoginSubscribeDynamic />} duration={700}/>
        </div>
      );
    }

    return (
      <div id="test">
      <ExtendedNav/>
      <SubscribeGate user={props.user} subbed={props.subbed}/>
      </div>
    );
}

export default Subscribe
