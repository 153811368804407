import React from "react"
import { useState, useEffect } from "react"

function LinkExternal(props){

    const [destination, setDestination] = useState("")

    useEffect(() => {

        setDestination(props.article.location)

    }, [props]);

      return (
            <div>
            <a href={destination}>
            <h1>
            <span className="over">{props.article.title}</span>
            </h1>
            <p style={{fontWeight: 'normal'}}>{props.article.content}</p>
            <h1>
            <span className="over">{props.article.titlex}</span>
            </h1>
            <p style={{fontWeight: 'normal'}}>{props.article.contentx}</p>
            </a>
            </div>

      )

}

export default LinkExternal
