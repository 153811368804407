import React from "react"
import { useState } from "react"
import { auth } from '../services/firebase';
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"

import AccountDetailsDelete from "./AccountDetailsDelete"
import AccountDetailsUpdate from "./AccountDetailsUpdate"
import AccountDetailsUnsubscribe from "./AccountDetailsUnsubscribe"
import ForgotPassword from "./ForgotPassword"


import ExtendedNav from "./ExtendedNav"


function AccountDetails(props){

  const size = UseWindowSize();

  function signOutUser() {

    auth().signOut()

  }

  const [username, setUsername] = useState(false)
  const [email, setEmail] = useState(false)
  const [password, setPassword] = useState(false)
  const [elim, setElim] = useState(false)
  const [membership, setMembership] = useState(false)
  const [init, setInit] = useState(true)

  function chooseUsername(){
    setUsername(true)
    setEmail(false)
    setPassword(false)
    setMembership(false)
    setElim(false)
    setInit(false)
  }

  function chooseEmail(){
    setUsername(false)
    setEmail(true)
    setPassword(false)
    setMembership(false)
    setElim(false)
    setInit(false)
  }

  function choosePassword(){
    setUsername(false)
    setEmail(false)
    setPassword(true)
    setMembership(false)
    setElim(false)
    setInit(false)
  }

  function chooseMembership(){
    setUsername(false)
    setEmail(false)
    setPassword(false)
    setMembership(true)
    setElim(false)
    setInit(false)
  }

  function chooseDelete(){
    setUsername(false)
    setEmail(false)
    setPassword(false)
    setMembership(false)
    setElim(true)
    setInit(false)
  }


  if (!props.subbed && init) {

    return (
    <div>
    <ExtendedNav/>
    <div className="centicons" style={{textAlign:"center"}}>
    <section>
    <div className="centicons" style={{}}>
    <div
          className="profile centpf">
      <p  onClick={chooseUsername}>
      <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>username</em></span>
      </p>
      <p  onClick={chooseEmail}>
      <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>email</em></span>
      </p>
      <p  onClick={choosePassword}>
      <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>password</em></span>
      </p>
      <Link to="/subscribe">
      <p >
      <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>membership</em></span>
      </p>
      </Link>
      <p  onClick={chooseDelete}>
      <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>delete account</em></span>
      </p>
    </div>
    <Link to="/profile">
    </Link>
    </div>
    <hr/>
    <button className='btn extra double' onClick={() => signOutUser()}>Sign Out</button>
    </section>
    </div>
    </div>
  );

  } else if (init) {

        return (
        <div>
        <ExtendedNav/>
          <div className="centicons" style={{textAlign:"center"}}>
        <section>
        <div className="centicons">
        <div
            className="profile centpf">
          <p  onClick={chooseUsername}>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>username</em></span>
          </p>
          <p  onClick={chooseEmail}>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>email</em></span>
          </p>
          <p  onClick={choosePassword}>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>password</em></span>
          </p>
          <p  onClick={chooseMembership}>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>membership</em></span>
          </p>
          <p  onClick={chooseDelete}>
          <span className="over pointy" style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold", lineHeight:"1.2em"} : {fontSize:"1.2em", fontWeight:"bold", lineHeight:"1.2em"}}><em>delete account</em></span>
          </p>
        </div>
        <Link to="/profile">
        </Link>
        </div>
        <hr/>
        <button className='btn extra double' onClick={() => signOutUser()}>Sign Out</button>
        </section>
        </div>
        </div>
      );

  } else if (username) {

      return (

        <div>
        <ExtendedNav/>
        <AccountDetailsUpdate field={"username"} user={props.user} subbed={props.subbed}/>
        </div>

      )

  } else if (email) {

      return (

        <div>
        <ExtendedNav/>
        <AccountDetailsUpdate field={"email"} user={props.user} subbed={props.subbed}/>
        </div>

      )

  } else if (password) {

      return (

        <div>
        <ForgotPassword />
        </div>

      )

  } else if (membership) {

      return (

        <div>
        <ExtendedNav/>
        <AccountDetailsUnsubscribe user={props.user}/>
        </div>

      )

  } else {

      return (

        <div>
        <ExtendedNav/>
        <AccountDetailsDelete user={props.user}/>
        </div>

      )

  }

}

export default AccountDetails
