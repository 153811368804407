import React from "react"

function Spinner(){
    return (
        <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
        </div>
    )
}

export default Spinner
