import React from "react"
import {Link} from "react-router-dom";
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

import FadeInSection from "./FadeInSection"
import DateModalCalc from "./DateModalCalc"

import IconBarSocial from "./IconBarSocial"
import IconBarSocialSwitch from "./IconBarSocialSwitch"

import Paradise from "./Paradise"

function GridVerticaFreeForm(props){

    const size = UseWindowSize()

    const [first, setFirst] = useState([])
    const [others, setOthers] = useState([])

    const [themeSwitch, setthemeSwitch] = useState(false)

    useEffect(() => {
      if(props.articles[0]){
        setFirst([props.articles[0]])
        const [, ...rest] = props.articles
        setOthers(rest)
      }

      /*

      if(!props.location){
          setthemeSwitch(false)
      } else {
        if(props.location.pathname=="/form"){
          setthemeSwitch(false)
        } else {
          setthemeSwitch(true)
        }
      }

      */

      /*

      if(props.location && !props.location.pathname == "/form"){
          setthemeSwitch(false)
      } else {
          setthemeSwitch(true)
      }

      */

      if(window.location.pathname == "/form"){
          setthemeSwitch(false)
      } else {
          setthemeSwitch(true)      
      }

      console.log(props.location)
    }, [props.articles, props.location]);

      return (
        <article className="post">
        {first.length != 0 && first.map(article => (
          <FadeInSection>
          <div id="test">
            <p className="timing">{article.topic.toUpperCase()}</p>
            <hr/>
            <Link to={article.location}>
            <h1 style={{textAlign:"center"}}>{article.title}</h1>
            </Link>
            {article.paras.map(para => (
            <div>
            <p>{para}</p>
            </div>
            ))}
            <Link to={article.author}>
            <h1 style={{textAlign:"right"}}>{article.author}</h1>
            </Link>
            <DateModalCalc article={article}/>
            <Paradise art={true}/>
            {themeSwitch &&
            <IconBarSocialSwitch />
            }
            {!themeSwitch &&
            <IconBarSocial />
            }
          </div>
          </FadeInSection>
        ))}
        {others.length != 0 && others.map(article => (
          <FadeInSection>
          <div>
            <hr/>
            <p className="timing">{article.topic.toUpperCase()}</p>
            <Link to={article.location}>
            <h1 style={{textAlign:"center"}}>{article.title}</h1>
            </Link>
            {article.paras.map(para => (
            <div>
            <p>{para}</p>
            </div>
            ))}
            <Link to={article.author}>
            <h1 style={{textAlign:"right"}}>{article.author}</h1>
            </Link>
            <DateModalCalc article={article}/>
            <p className="timingext">{article.geo.toUpperCase()}</p>
          </div>
          </FadeInSection>
        ))}
        </article>
      )

    }

export default GridVerticaFreeForm
