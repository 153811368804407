import React from "react"
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

import WaitDisplay from "./WaitDisplay"

function IconBarSocialSwitch(props){

const size = UseWindowSize();

      function themeSwitch(){

        const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
        const currentTheme = localStorage.getItem('theme');

        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);

            if (currentTheme === 'dark') {
                toggleSwitch.checked = true;
            }
        } else {
            document.documentElement.setAttribute('data-theme', 'light');
            toggleSwitch.checked = false;
        }

        function switchTheme(e) {
            if (e.target.checked) {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark');
            }
            else {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
            }
        }

        toggleSwitch.addEventListener('change', switchTheme, false);


      }

      const [clicked, setClicked] = useState(false)

      function handleClick(){
        if(clicked){
          setClicked(false)
        } else {
          setClicked(true)
        }
      }

      const [twitterLink, setTwitterLink] = useState("")
      const [igLink, setIgLink] = useState("")
      const [fbLink, setFbLink] = useState("")

      useEffect(() => {

          setTwitterLink("https://www.twitter.com/" + "kujeera")
          setIgLink("https://www.instagram.com/" + "kujeera")

    }, []);

      useEffect(() => {

        themeSwitch()

      }, []);

      return (
        <div>
        <div className="icons">
        <div className="lefticons">
        <a href={twitterLink}>
        <span className="iconButton" style={{marginRight:20}} onClick={() => handleClick()}><ion-icon name="logo-twitter" style={size.width > 1000 ? {fontSize:30} : {fontSize:25}}></ion-icon></span>
        </a>
        <a href={igLink}>
        <span className="iconButton" onClick={() => handleClick()}><ion-icon name="logo-instagram" style={size.width > 1000 ? {fontSize:30} : {fontSize:25}}></ion-icon></span>
        </a>
        </div>

        <div class="theme-switch-wrapper" style={{display:"block"}}id="myBTN">
          <label class="theme-switch" for="checkbox">
            <input type="checkbox" id="checkbox" />
            <div class="slider round"></div>
          </label>
        </div>

        </div>
        </div>
      )
    }

export default IconBarSocialSwitch
