import React from "react"
import {withRouter} from 'react-router';

import Subscribe from "./Subscribe"
import SubscriberCurrentNewsletterInit from "./SubscriberCurrentNewsletterInit"
import SubscriberCurrentNewsletter from "./SubscriberCurrentNewsletter"
import IconBarSocial from "./IconBarSocial"
import DateModalCalcVolume from "./DateModalCalcVolume"

import ExtendedNav from "./ExtendedNav"
import WaitDisplay from "./WaitDisplay"

function Newsletter(props){

    if(!props.subbed) {

       return (

        <div>
            <WaitDisplay Component={<Subscribe user={props.user} subbed={props.subbed}/>} duration={500}/>
        </div>

      )
    
    } else {
       return (

        <div>
            <ExtendedNav/>
            <section>
            <p className="timing">NEWSLETTER</p>
            <hr/>
            <DateModalCalcVolume/>
            </section>
            <SubscriberCurrentNewsletterInit />
            <SubscriberCurrentNewsletter />
            <section>
            <hr/>
            <IconBarSocial/>
            </section>
        </div>

      )

    } 

}

export default withRouter(Newsletter)
