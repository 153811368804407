import React, { Component } from "react";
import { db } from '../services/firebase';
import { time } from '../services/firebase';

import AdminGridVertica from "./AdminGridVertica"
import AdminArticle from "./AdminArticle"



export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,

      title: "",
      content: "",
      titlex: "",
      contentx: "",

      titley: "",

      url: "",
      location: "",

      alt: "",

      p1: "",
      p2: "",
      p3: "",

      per1: "",
      per2: "",
      per3: "",
      per4: "",

      history: "",
      city: "",
      country: "",

      base: "",

      tag1: '',
      tag2: '',
      tag3: '',
      tag4: '',

      tag5: '',
      tag6: '',

      tag7: '',
      tag8: '',
      tag9: '',

      timestamp: time.FieldValue.serverTimestamp(),

      photocr: '',

      geotag: '',



    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

          db
          .collection('pagesx')
          .doc(this.state.title)
          .set({

            title: this.state.title,
            content: this.state.content,
            titlex: this.state.titlex,
            contentx: this.state.contentx,
            titley: this.state.titley,

            history: this.state.history,

            url: this.state.url,
            location: this.state.location,
            alt: this.state.alt,

            base: this.state.city + " , " + this.state.country,

            p1: this.state.p1,
            p2: this.state.p2,
            p3: this.state.p3,

            per1: this.state.per1,
            per2: this.state.per2,
            per3: this.state.per3,
            per4: this.state.per4,

            tags: ['#' + this.state.tag1.toLowerCase(), '#' + this.state.tag2.toLowerCase(),
                    '#' + this.state.tag3.toLowerCase(), '#' + this.state.tag4.toLowerCase(),
                    '#' + this.state.tag5.toLowerCase(), '#' + this.state.tag6.toLowerCase(),
                    '#' + this.state.tag7.toLowerCase(), '#' + this.state.tag8.toLowerCase(),
                    '#' + this.state.tag9.toLowerCase()],

            timestamp: this.state.timestamp,

            photocr: this.state.photocr,

          })
          .then(() => {
          });

                db
                .collection('coversx')
                .doc(this.state.title)
                .set({

                  title: this.state.title,
                  content: this.state.content,
                  titlex: this.state.titlex,
                  contentx: this.state.contentx,

                  url: this.state.url,
                  location: this.state.location,
                  alt: this.state.alt,

                  timestamp: this.state.timestamp,
                })
                .then(() => {
                });

                db
                .collection(this.state.geotag)
                .doc(this.state.title)
                .set({

                  title: this.state.title,
                  content: this.state.content,
                  titlex: this.state.titlex,
                  contentx: this.state.contentx,

                  url: this.state.url,
                  location: this.state.location,
                  alt: this.state.alt,

                  timestamp: this.state.timestamp,

                })
                .then(() => {
                });

  }

  render() {
    return (
      <div>
        <form
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <h1>
            Admin for NYT
          </h1>
          <p>
            Upload an article below.
          </p>
          <div>
            <input
              placeholder="title"
              name="title"
              onChange={this.handleChange}
              value={this.state.title}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="content"
              name="content"
              onChange={this.handleChange}
              value={this.state.content}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="titlex"
              name="titlex"
              onChange={this.handleChange}
              value={this.state.titlex}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="contentx"
              name="contentx"
              onChange={this.handleChange}
              value={this.state.contentx}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="titley"
              name="titley"
              onChange={this.handleChange}
              value={this.state.titley}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="url"
              name="url"
              onChange={this.handleChange}
              value={this.state.url}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="location"
              name="location"
              onChange={this.handleChange}
              value={this.state.location}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="photcr"
              name="photocr"
              onChange={this.handleChange}
              value={this.state.photocr}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="city"
              name="city"
              onChange={this.handleChange}
              value={this.state.city}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="country"
              name="country"
              onChange={this.handleChange}
              value={this.state.country}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="paragraph one"
              name="p1"
              onChange={this.handleChange}
              value={this.state.p1}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="paragraph two"
              name="p2"
              onChange={this.handleChange}
              value={this.state.p2}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="paragraph three"
              name="p3"
              onChange={this.handleChange}
              value={this.state.p3}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="history"
              name="history"
              onChange={this.handleChange}
              value={this.state.history}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="person one"
              name="per1"
              onChange={this.handleChange}
              value={this.state.per1}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="person two"
              name="per2"
              onChange={this.handleChange}
              value={this.state.per2}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="person three"
              name="per3"
              onChange={this.handleChange}
              value={this.state.per3}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="person four"
              name="per4"
              onChange={this.handleChange}
              value={this.state.per4}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="tag1"
              name="tag1"
              onChange={this.handleChange}
              value={this.state.tag1}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag2"
              name="tag2"
              onChange={this.handleChange}
              value={this.state.tag2}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag3"
              name="tag3"
              onChange={this.handleChange}
              value={this.state.tag3}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag4"
              name="tag4"
              onChange={this.handleChange}
              value={this.state.tag4}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag5"
              name="tag5"
              onChange={this.handleChange}
              value={this.state.tag5}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag6"
              name="tag6"
              onChange={this.handleChange}
              value={this.state.tag6}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag7"
              name="tag7"
              onChange={this.handleChange}
              value={this.state.tag7}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag8"
              name="tag8"
              onChange={this.handleChange}
              value={this.state.tag8}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="tag9"
              name="tag9"
              onChange={this.handleChange}
              value={this.state.tag9}
              className="inputBox"
            />
          </div>
          <hr />
          <div>
            <input
              placeholder="geotag"
              name="geotag"
              onChange={this.handleChange}
              value={this.state.geotag}
              className="inputBox"
            />
          </div>
          <div>
            <input
              placeholder="alt"
              name="alt"
              onChange={this.handleChange}
              value={this.state.alt}
              className="inputBox"
            />
          </div>
          <div>
            {this.state.error ? (
              <p>{this.state.error}</p>
            ) : null}
            <button type="submit" className="btn extra"><em>Upload Article</em></button>
          </div>
          <hr />
        </form>
        <section>
        <AdminGridVertica
            articles={[{
                title: this.state.title,
                content: this.state.content,
                titlex: this.state.titlex,
                contentx: this.state.contentx,

                alt: this.state.alt,

                url: this.state.url,
            }]}
        />
        <AdminArticle
            articles={[{
                title: this.state.title,
                content: this.state.content,
                titlex: this.state.titlex,
                contentx: this.state.contentx,

                alt: this.state.alt,

                titley: this.state.titley,

                url: this.state.url,
                attribute: this.state.atrribute,

                history: this.state.history,

                person1: this.state.per1,
                person2: this.state.per2,
                person3: this.state.per3,
                person4: this.state.per4,

                p1: this.state.p1,
                p2: this.state.p2,
                p3: this.state.p3,

                base: this.state.city + " , " + this.state.country,

                tags: ['#' + this.state.tag1,'#' +  this.state.tag2, '#' + this.state.tag3, '#' + this.state.tag4,
                        '#' + this.state.tag5, '#' + this.state.tag6, '#' + this.state.tag7,
                        '#' + this.state.tag8, '#' + this.state.tag9]
            }]}
        />
      </section>
      </div>
    );
  }
}
