import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../services/firebase';


import ExtendedNav from "./ExtendedNav"

export default class ForgotPassword extends Component {

  constructor(props) {
      super(props);
      this.state = {
        error: null,
        email: '',
        success: false,
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
  }

  handleSubmit(event) {

    event.preventDefault();

    auth().sendPasswordResetEmail(this.state.email)
        .then(() => {
      // Email sent.
        this.setState({success: true})

    }).catch(e => {
      // An error happened.
        this.setState({error: e.message})
    });

  }


  render() {

  if(!this.state.success){
      return (
          <div>
          <ExtendedNav/>
          <div className="centicons" style={{textAlign:"center"}}>
          <section>
            <form onSubmit={this.handleSubmit}>
              <h1>
                Password Reset
              </h1>
              <p>Fill in the form below to reset your password.</p>
              <div>
                <input placeholder="Email" name="email" type="email" onChange={this.handleChange} value={this.state.email} className="inputBox"></input>
              </div>
              <div>
                {this.state.error ? <p>{this.state.error}</p> : null}
                <button type="submit" className="btn extra">Send Reset Link</button>
              </div>
              <p>Know your password? <Link to="/login">Login</Link></p>
            </form>
          </section>
          </div>
          </div>
      )
  } else {
        return (
          <div>
            <div>
            <ExtendedNav/>
            <section>
            <hr/>
            <div style={{textAlign:"center"}}>
            <h1>Password Reset</h1>
            <p>A password reset email has been sent to</p><div><p style={{textDecoration: "underline"}}>{this.state.email}</p></div>
            </div>
            <hr/>
            </section>
            </div>
          </div>
        )
  }

  }
  }
