import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import GridVertica from "./GridVertica"
import AuthorProfile from "./AuthorProfile"

import ExtendedNav from "./ExtendedNav"
import WaitDisplay from "./WaitDisplay"

import ArtistInRes from "./ArtistInRes"
import GridVerticaFreeForm from "./GridVerticaFreeForm"
import IconBarSocial from "./IconBarSocial"

function Article(props){

    const [articles, setArticles] = useState([])
    const [author, setAuthor] = useState([])
    const [free, setFree] = useState([])

    const [initializing, setInitializing] = useState(true);

    useEffect(() => {

      if(props.location.pathname.includes("@") && props.location.pathname.includes("~")){

                setInitializing(true)

          /*

            const currentlocation = props.location.pathname
            const artistname = currentlocation.split('~')[0]

            const subscriber = db
            .collection('artists')
            .where("location", "==", artistname)
            .limit(1)
            .get()
            .then(snap => {
              const data = snap.docs.map(doc => doc.data())
              setAuthor(data)
            });

          */

          /*

          const currentlocation = props.location.pathname
          const artistname = currentlocation.split('~')[0]

          */

          const subscriber = db
          .collection('coverartists')
          .where("location", "==", props.location.pathname)
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setAuthor(data)
          });

        } else if(props.location.pathname.includes("@")){

              setInitializing(true)

                const subscriber = db
                .collection('artists')
                .where("location", "==", props.location.pathname)
                .limit(1)
                .get()
                .then(snap => {
                  const data = snap.docs.map(doc => doc.data())
                  setAuthor(data)
                });

          } else if (props.location.pathname.includes("&"))  {

            setInitializing(true)

              const subscriber = db
                .collection('art')
                .where("location", "==", props.location.pathname)
                .limit(1)
                .get()
                .then(snap => {
                  const data = snap.docs.map(doc => doc.data())
                  setArticles(data)
                });

          } else if (props.location.pathname.includes("~"))  {

              setInitializing(true)

              const subscriber = db
                .collection('freeform')
                .where("location", "==", props.location.pathname)
                .limit(1)
                .get()
                .then(snap => {
                  const data = snap.docs.map(doc => doc.data())
                  setFree(data)
                });

        }

            setInitializing(false)

    }, [props.location.pathname]);

    if (initializing) return null;

    if(props.location.pathname.includes("&")){

      if (articles.length !== 0) {
          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<GridVertica articles={articles}/>} duration={0}/>
            </section>
            </div>
                )
      } else {
          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<hr/>} duration={300}/>
            <div style={{textAlign:"center"}}>
            <WaitDisplay Component={<h1>Request Error</h1>} duration={300}/>
            <WaitDisplay Component={<p>The Article Does Not Exist!</p>} duration={300}/>
            </div>
            <WaitDisplay Component={<hr/>} duration={300}/>
            </section>
            </div>
                  )}

    } else if (props.location.pathname.includes("@")) {

      if (author.length !== 0) {

          if(props.location.pathname.includes("~")){
              return (
                    <div>
                    <ExtendedNav/>
                    <WaitDisplay Component={<ArtistInRes cover={true} searchArticles={author}/>} duration={0}/>
                    </div>
                  )
          } else {
            return (
                  <div>
                  <ExtendedNav/>
                  <WaitDisplay Component={<ArtistInRes searchArticles={author}/>} duration={0}/>
                  </div>
                )
          }

      } else {

          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<hr/>} duration={400}/>
            <div style={{textAlign:"center"}}>
            <WaitDisplay Component={<h1>Request Error</h1>} duration={400}/>
            <WaitDisplay Component={<p>The Author Does Not Exist!</p>} duration={400}/>
            </div>
            <WaitDisplay Component={<hr/>} duration={400}/>
            </section>
            </div>
          )
        }

    } else if (props.location.pathname.includes("~")) {

      if (free.length !== 0) {

        return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<GridVerticaFreeForm articles={free}/>} duration={500}/>
            </section>
            </div>
        )

      } else {

          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<hr/>} duration={400}/>
            <div style={{textAlign:"center"}}>
            <WaitDisplay Component={<h1>Request Error</h1>} duration={400}/>
            <WaitDisplay Component={<p>The Article Does Not Exist!</p>} duration={400}/>
            </div>
            <WaitDisplay Component={<hr/>} duration={400}/>
            </section>
            </div>
          )

      }

    } else {

      return (
        <div>
        <ExtendedNav/>
        <section>
        <WaitDisplay Component={<hr/>} duration={400}/>
        <div style={{textAlign:"center"}}>
        <WaitDisplay Component={<h1>Request Error</h1>} duration={400}/>
        <WaitDisplay Component={<p>The Resource Does Not Exist!</p>} duration={400}/>
        </div>
        <WaitDisplay Component={<hr/>} duration={400}/>
        </section>
        </div>
      )

    }





    /*

    if(!props.location.pathname.includes("@")){

      if (articles.length !== 0) {
          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<GridVertica articles={articles}/>} duration={0}/>
            </section>
            </div>
                )
      } else {
          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<hr/>} duration={300}/>
            <div style={{textAlign:"center"}}>
            <WaitDisplay Component={<h1>Request Error</h1>} duration={300}/>
            <WaitDisplay Component={<p>The Article Does Not Exist!</p>} duration={300}/>
            </div>
            <WaitDisplay Component={<hr/>} duration={300}/>
            </section>
            </div>
                  )}

  } else {

      if (author.length !== 0) {
        return (
            <div>
            <ExtendedNav/>
            <WaitDisplay Component={<ArtistInRes searchArticles={author}/>} duration={500}/>
            </div>
        )

      } else {

          return (
            <div>
            <ExtendedNav/>
            <section>
            <WaitDisplay Component={<hr/>} duration={400}/>
            <div style={{textAlign:"center"}}>
            <WaitDisplay Component={<h1>Request Error</h1>} duration={400}/>
            <WaitDisplay Component={<p>The Author Does Not Exist!</p>} duration={400}/>
            </div>
            <WaitDisplay Component={<hr/>} duration={400}/>
            </section>
            </div>
          )}

      */

}

export default withRouter(Article)
