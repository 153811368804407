import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';

import GridVerticaFreeFormEditors from "./GridVerticaFreeFormEditors"

function GoldPageEditorsNoteSubscribe(props) {

    const [articles, setArticles] = useState([]);
    const [initializing, setInitializing] = useState(true)

    useEffect(() => {
        const subscriber = db
          .collection(props.database)
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setArticles(data);
            setInitializing(false);
          });
  }, [props.database]);

    if (initializing) return null;

    return (
        <div>
        <section>
            {0 ==1 && articles[0] &&
            <p className="timing">{articles[0].geo.toUpperCase()}</p>
            }
            <hr/>
            <GridVerticaFreeFormEditors articles={articles}/>
            {/*<p className="timingext">NEW YORK, NY</p>*/}
        </section>
        </div>
    );
}

export default GoldPageEditorsNoteSubscribe
