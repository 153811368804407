import React from "react"
import {Link} from "react-router-dom";
import UseWindowSize from "./UseWindowSize"

function ArtistInResGridVerticaFollow(props){

    const size = UseWindowSize()

            return (
              <article className="post">
                <Link to={props.article.location}>
                <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
                <div className="sidebarx" style={{zIndex: 950}}>

                <h1>
                <span className="over">{props.article.title}</span>
                </h1>
                <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{props.article.content}</p>
                <h1>
                <span className="over">{props.article.titlex}</span>
                </h1>
                <p style={ size.width > 1000 ? {fontWeight: 'normal'} : {fontWeight: 'normal', fontSize: ".7em"}}>{props.article.contentx}</p>

                </div>
                <img src={props.article.url}
                          style={{display: "block",
                                  width: "70%",
                                  height: "70%",
                                  objectFit: "cover",
                                 zIndex: 900}}
                          alt={props.article.alt}
                      />
                </div>
                </Link>
                <a href={props.article.author}>
                <h1 style={{textAlign:"right"}}>{props.article.author}</h1>
                </a>
                {props.article.geo &&
                <div>
                <p className="timing">{props.article.topic2.toUpperCase()}</p>
                <p className="timingext">{props.article.geo.toUpperCase()}</p>
                </div>
                }
                <div>
                <hr/>
                <p className="timing">ARTICLE SOUL</p>
                <p>{props.article.soul}</p>
                </div>
              </article>
            )

}

export default ArtistInResGridVerticaFollow
