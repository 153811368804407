import React from "react"
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

import GridVerticaGate from "./GridVerticaGate"
import GridVerticaGateInit from "./GridVerticaGateInit"

function GridVertica(props){

    const size = UseWindowSize()

    const [first, setFirst] = useState([])
    const [others, setOthers] = useState([])

    useEffect(() => {
      if(props.articles[0]){
        setFirst([props.articles[0]])
        const [, ...rest] = props.articles
        setOthers(rest)
      }
    }, [props]);

    if(first[0] && first[0].tag1 && 0==1){

      return (
        <article className="post">
        {first.length != 0 && first.map(article => (
          <div>
          <GridVerticaGateInit article={article} cover={props.cover}/>
          <h1>{article.tag1}</h1>
          <p>"{article.snip1}"</p>
          <h1>{article.tag2}</h1>
          <p>"{article.snip2}"</p>
          </div>
        ))}
        {others.length != 0 && others.map(article => (
          <div>
          <GridVerticaGate article={article} />
          <h1>{article.tag1}</h1>
          <p>"{article.snip1}"</p>
          <h1>{article.tag2}</h1>
          <p>"{article.snip2}"</p>
          </div>
        ))}
        </article>
      )

    } else {

      return (
        <article className="post">
        {first.length != 0 && first.map(article => (
          <div>
          <GridVerticaGateInit article={article} cover={props.cover}/>
          {/*<hr/>*/}
          </div>
        ))}
        {others.length != 0 && others.map(article => (
          <div>
          <GridVerticaGate article={article} />
          </div>
        ))}
        </article>
      )
    }


    }

export default GridVertica
