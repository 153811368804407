import React from "react"
import {useState, useEffect} from "react"
import {Link} from "react-router-dom";
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import AuthorAbout from "./AuthorAbout"
import AuthorContact from "./AuthorContact"
import GridVertica from "./GridVertica"
import GridVerticaFreeForm from "./GridVerticaFreeForm"

import UseWindowSize from "./UseWindowSize"

function AuthorProfile(props){

  const size = UseWindowSize();


  const [about, setabout] = useState(true);
  const [contact, setcontact] = useState(false);
  const [articles, setarticles] = useState(false);

  const [username, setUsername] = useState("");

  function initial(){
    setabout(true)
    setcontact(false)
    setarticles(false)
  }

  useEffect(() => {
    initial()
  }, [props.location.pathname]);

  useEffect(() => {
    setUsername("/" + props.author)
  }, [props.author]);

  function chooseabout() {

    setabout(true)
    setcontact(false)
    setarticles(false)

  }

  function choosecontact() {

    setabout(false)
    setcontact(true)
    setarticles(false)

  }

  function choosearticles() {

    setabout(false)
    setcontact(false)
    setarticles(true)

  }

  const [written, setWritten] = useState(null)
  const [free, setFree] = useState(null)

  useEffect(() => {

          const subscriber = db
          .collection('coversx')
          .where("author", "==", props.location.pathname.replace('/', ''))
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setWritten(data)
          }); 

}, [props.location.pathname]);

useEffect(() => {

  const subscriber = db
  .collection('freeform')
  .where("author", "==", props.location.pathname.replace('/', ''))
  .get()
  .then(snap => {
    const data = snap.docs.map(doc => doc.data())
    setFree(data)
  }); 

}, [props.location.pathname]);

  if (about){

    return (
      <div>
      <section>
      <div className="centicons">
      <div
        >
      <p style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold"} : {fontSize:"1em", fontWeight:"bold"}}><span className="over"><em>{props.author.username}</em></span></p>
      </div>
      <Link to="/">
      <span className="iconButton"><ion-icon name="ellipsis-horizontal-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:40}}></ion-icon></span>
      </Link>
      </div>
      </section>
      <section>
      <ul>
      <li><span onClick={chooseabout} style={{textDecoration:"underline", fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ah</em></p></span></li>
      <li><span onClick={choosecontact} style={{fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ma</em></p></span></li>
      <li><span onClick={choosearticles} style={{fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ud</em></p></span></li>
      </ul>
      </section>
      <div id="test">
      <section>
      <AuthorAbout author={props.author}/>
      <AuthorContact author={props.author}/>
      </section>
      </div>
      </div>
    );


  } else if (contact) {

    return (
      <div>
      <section>
      <div className="centicons">
      <div
        >
    <p style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold"} : {fontSize:"1em", fontWeight:"bold"}}><span className="over"><em>{props.author.username}</em></span></p>
      </div>
      <Link to="/">
      <span className="iconButton"><ion-icon name="ellipsis-horizontal-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:40}}></ion-icon></span>
      </Link>
      </div>
      </section>
      <section>
      <ul>
      <li><span onClick={chooseabout} style={{fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ah</em></p></span></li>
      <li><span onClick={choosecontact}  style={{textDecoration:"underline", fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ma</em></p></span></li>
      <li><span onClick={choosearticles} style={{fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ud</em></p></span></li>
      </ul>
      </section>
      <section>
      <GridVerticaFreeForm articles={free}/>  
      </section>
      </div>
    );


  } else {

    return (
      <div>
      <section>
      <div className="centicons">
      <div
        >
    <p style={size.width > 1000 ? {fontSize:"1.5em", fontWeight:"bold"} : {fontSize:"1em", fontWeight:"bold"}}><span className="over"><em>{props.author.username}</em></span></p>
      </div>
      <Link to="/">
      <span className="iconButton"><ion-icon name="ellipsis-horizontal-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:40}}></ion-icon></span>
      </Link>
      </div>
      </section>
      <section>
      <ul>
      <li><span onClick={chooseabout} style={{fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ah</em></p></span></li>
      <li><span onClick={choosecontact} style={{fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ma</em></p></span></li>
      <li><span onClick={choosearticles} style={{textDecoration:"underline", fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#ud</em></p></span></li>
      </ul>
      </section>
      <section>
      <GridVertica articles={written}/>
      </section>
      </div>
    );


  }

}

export default withRouter(AuthorProfile)
