import React from "react"

export default function AuthorAbout(props){

    return (
        
        <div>
{/*    
        <div>
            {props.author.paras &&
            <AboutPageGridVertica articles={[{
                title: props.author.username,
                titlex: props.author.headline,
                url: props.author.url,
                content: props.author.paras[0],
                contentx: props.author.paras[1],
                }]}
            />}    
        </div>
*/}      
        <div>
            
            {props.author.headline &&
            <h1 style={{textAlign:"center"}}>{props.author.headline}</h1>
            }
           
            {props.author.paras && props.author.paras.map(para => (
            <div> 
            <p>{para}</p>    
            </div>
            ))} 
 {/*       
            <div className="container-div">
            { props.author.paras &&  
                <img src={props.author.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={props.author.username}        
                />
            }  
            </div>
*/}            
        </div>        

        </div>
    )

}