import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';

import ArtistInResFreeFormInitExt from "./ArtistInResFreeFormInitExt"

function ArtistInResFreeFormInit(props) {

  /*
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const subscriber = db
          .collection(props.database)
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setArticles(data);
          });
  }, [props.database]);
  */

    return (
        <div>
        <section>
            {0==1 && props.articles[0] &&
            <p className="timing">{props.articles[0].geo.toUpperCase()}</p>
            }
            <hr/>
            <ArtistInResFreeFormInitExt articles={props.articles} main={props.main}/>
            {/*<p className="timingext">NEW YORK, NY</p>*/}
        </section>
        </div>
    );
}

export default ArtistInResFreeFormInit
