import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import NewsletterGridVertica from "./NewsletterGridVertica"
import NewsletterFreeForm from "./NewsletterFreeForm"

import FadeInSection from "./FadeInSection"

function SubscriberCurrentNewsletter(props){

    const [articles, setArticles] = useState([{location:"this array is not empty"}])

    useEffect(() => {
      const subscriber = db
        .collection('newsletter')
        .orderBy('timestamp', 'desc')
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          setArticles(data)
        });
    }, []);

    const [first, setFirst] = useState()
    const [others, setOthers] = useState([])

    useEffect(() => {
      if(articles[0]){
        setFirst(articles[0])
        const [, ...rest] = articles
        setOthers(rest)
      }
    }, [articles]);

      if (articles.length !== 0) {
          return (
              <div>
                {first && first.topic && 
                    <div>
                    <section>
                    <FadeInSection>
                    <hr/>
                    <p className="timingext">COVER</p>
                    <NewsletterGridVertica article={first}/>
                    </FadeInSection>
                    <FadeInSection>
                    <hr/>
                    <p className="timing">{first.topic.toUpperCase()}</p>
                    <NewsletterFreeForm article={first}/>
                    </FadeInSection>
                    </section>
                    </div>
                }    
              {others.map(article => (  
                    <div>
                    <section>  
                    <FadeInSection>
                    <hr/>
                    <p className="timing">UPCOMING</p>
                    <NewsletterGridVertica article={article}/>
                    </FadeInSection>
                    <FadeInSection>
                    <hr/>
                    <p className="timing">{article.topic.toUpperCase()}</p>
                    <NewsletterFreeForm article={article}/>
                    </FadeInSection>
                    </section>
                    </div>
              ))}
              </div>
          )
      } else {
            return (
              <div>
              <p>An Error Has Occured</p>
              </div>
            )}
}

export default withRouter(SubscriberCurrentNewsletter)
