import React from "react"

function ArtistInResDateModalCalc(props){

    var d = new Date();
    var year = d.getFullYear();
    var monthx = d.getMonth();
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();
    var month = "";

    var da = props.article.timestamp.toDate();
    var yeara = da.getFullYear();
    var monthxa = da.getMonth();
    var daya = da.getDate();
    var hoursa = da.getHours();
    var minutesa = da.getMinutes();
    var secondsa = da.getSeconds();
    var montha = "";

    switch(monthxa) {
        case 0:
            montha = "Jan.".toUpperCase()
          break;
        case 1:
            montha = "Feb.".toUpperCase()
          break;
        case 2:
            montha = "Mar.".toUpperCase()
          break;
        case 3:
            montha = "Apr.".toUpperCase()
          break;
        case 4:
            montha = "May.".toUpperCase()
          break;
        case 5:
            montha = "Jun.".toUpperCase()
          break;
        case 6:
            montha = "Jul.".toUpperCase()
          break;
        case 7:
            montha = "Aug.".toUpperCase()
          break;
        case 8:
            montha = "Sep.".toUpperCase()
          break;
        case 9:
            montha = "Oct.".toUpperCase()
          break;
        case 10:
            montha = "Nov.".toUpperCase()
          break;
        case 11:
            montha = "Dec.".toUpperCase()
          break;

        default:
            montha = "Jan.".toUpperCase()
      }


    if(Math.abs(monthxa - monthx) != 0) {

        if(Math.abs(monthxa - monthx) == 1)

            return (

                <div>
                <p className="timing" >{Math.abs(monthxa - monthx)} MONTH AGO</p>
                </div>

            )

        else {

            return (

                <div>
                <p className="timing" >{daya} {montha} {yeara}</p>
                </div>

            )

        }

    } else if (daya - day != 0) {

        if(Math.abs(daya - day) == 1) {

            return (

                <div>
                <p className="timing" >{Math.abs(daya - day)} DAY AGO</p>
                </div>

            )

        } else if (Math.abs(daya - day) > 21) {

            return (

                <div>
                <p className="timing" >{3} WEEKS AGO</p>
                </div>

            )

        } else if (Math.abs(daya - day) > 14) {

            return (

                <div>
                <p className="timing" >{2} WEEKS AGO</p>
                </div>

            )

        } else if (Math.abs(daya - day) > 7) {

            return (

                <div>
                <p className="timing" >{1} WEEK AGO</p>
                </div>

            )

        } else {

            return (

                <div>
                <p className="timing" >{Math.abs(daya - day)} DAYS AGO</p>
                </div>

            )

        }

    } else if (hoursa - hours != 0)   {

        if(Math.abs(hoursa - hours) == 1)

            return (

                <div>
                <p className="timing" >{Math.abs(hoursa - hours)} HOUR AGO</p>
                </div>

            )

        else {

            return (

                <div>
                <p className="timing" >{Math.abs(hoursa - hours)} HOURS AGO</p>
                </div>

            )

        }

    } else if (minutesa - minutes != 0)   {

        if(Math.abs(minutesa - minutes) == 1)

            return (

                <div>
                <p className="timing" >{Math.abs(minutesa - minutes)} MINUTE AGO</p>
                </div>

            )

        else {

            return (

                <div>
                <p className="timing" >{Math.abs(minutesa - minutes)} MINUTES AGO</p>
                </div>

            )

        }


    } else if (secondsa - seconds != 0)   {

        if(Math.abs(secondsa - seconds) == 1)

            return (

                <div>
                <p className="timing" >{Math.abs(secondsa - seconds)} SECOND AGO</p>
                </div>

            )

        else {

            return (

                <div>
                <p className="timing" >{Math.abs(secondsa - seconds)} SECONDS AGO</p>
                </div>

            )

        }


    } else {

        return (

            <div>
            <p className="timing" >{daya} {montha} {yeara}</p>
            </div>

          )

    }


}

export default ArtistInResDateModalCalc
