import React from "react"
import {Link} from "react-router-dom";
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

import FadeInSection from "./FadeInSection"
import DateModalCalc from "./DateModalCalc"

import IconBarSocial from "./IconBarSocial"
import IconBarSocialSwitch from "./IconBarSocialSwitch"

import Paradise from "./Paradise"

function ArtistInResFreeFormInitExt(props){

    const size = UseWindowSize()

    const [first, setFirst] = useState([])
    const [others, setOthers] = useState([])

    useEffect(() => {
      if(props.articles[0]){
        setFirst([props.articles[0]])
        const [, ...rest] = props.articles
        setOthers(rest)
      }
    }, [props.articles]);

      return (
        <article className="post">
        {first.length != 0 && first.map(article => (

          <div id="test">
            <h1 style={{textAlign:"center"}}>{article.titlez}</h1>
            {article.paras.map(para => (
            <div>
            <p>{para}</p>
            </div>
            ))}
            {/*<Link to={article.author}>
            <h1 style={{textAlign:"right"}}>{article.author}</h1>
            </Link>
            */}
            <DateModalCalc article={article}/>
            <Paradise art={true}/>
            {props.main &&
            <IconBarSocialSwitch />
            }
            {!props.main  &&
            <IconBarSocial twitter={article.twitter} instagram={article.instagram}/>
            }
          </div>

        ))}
        {others.length != 0 && others.map(article => (

          <div>
            <h1 style={{textAlign:"center"}}>{article.title}</h1>
            {article.paras.map(para => (
            <div>
            <p>{para}</p>
            </div>
            ))}
            {/*<Link to={article.author}>
            <h1 style={{textAlign:"right"}}>{article.author}</h1>
            </Link>
            */}
            <DateModalCalc article={article}/>
          </div>

        ))}
        </article>
      )

    }

export default ArtistInResFreeFormInitExt
