import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import ArtistInResGridVerticaFollow from "./ArtistInResGridVerticaFollow"
import ArtistInResFreeForm from "./ArtistInResFreeForm"

import FadeInSection from "./FadeInSection"
import IconBarSocialSwitch from "./IconBarSocialSwitch"

function ArtistInResFollow(props){

    const [articles, setArticles] = useState([])

    useEffect(() => {
      if(!props.written){
        const subscriber = db
          .collection('covers')
          .orderBy('timestamp', 'desc')
          .limit(2)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setArticles(data)
            console.log(data)
          });
      } else {
        setArticles(props.written)
      }

    }, [props.written]);

    const [first, setFirst] = useState()
    const [others, setOthers] = useState([])
    const [final, setFinal] = useState([])

    useEffect(() => {
      if(articles[0]){
        setFirst(articles[0])
        setFinal([articles[articles.length-1]])
        const [, ...rest] = articles
        rest.pop()
        setOthers(rest)
      }
    }, [articles]);

      if (articles.length !== 0) {
          return (
              <div>
                {first && first.topic &&
                    <div>
                    <section>
                    <FadeInSection>
                    <hr/>
                    <p className="timingext">COVER</p>
                    <ArtistInResGridVerticaFollow article={first}/>
                    </FadeInSection>
                    <FadeInSection>
                    <cr/>
                    {first.topic &&
                    <p className="timingext">{first.topic.toUpperCase()}</p>
                    }
                    <ArtistInResFreeForm article={first}/>
                    </FadeInSection>
                    </section>
                    </div>
                }
              {others[0] && others.map(article => (
                    <div>
                    <section>
                    <FadeInSection>
                    <hr/>
                    <p className="timingext">UPCOMING</p>
                    <ArtistInResGridVerticaFollow article={article}/>
                    </FadeInSection>
                    <FadeInSection>
                    <cr/>
                    {article.topic &&
                    <p className="timingext">{article.topic.toUpperCase()}</p>
                    }
                    <ArtistInResFreeForm article={article}/>
                    </FadeInSection>
                    </section>
                    </div>
              ))}
              {final[0] && final.map(article => (
                    <div>
                    <section>
                    <FadeInSection>
                    <hr/>
                    <p className="timingext">UPCOMING</p>
                    <ArtistInResGridVerticaFollow article={article}/>
                    </FadeInSection>
                    <FadeInSection>
                    <cr/>
                    {article.topic &&
                    <p className="timingext">{article.topic.toUpperCase()}</p>
                    }
                    <ArtistInResFreeForm article={article}/>
                    <hr/>
                    <IconBarSocialSwitch/>
                    </FadeInSection>
                    </section>
                    </div>
              ))}
              </div>
          )
      } else {
            return (
              <div>
              </div>
            )}
}

export default withRouter(ArtistInResFollow)
