import React from "react"
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

function IconBarSocial(props){

      const size = UseWindowSize();

      const [clicked, setClicked] = useState(false)

      function handleClick(){
        if(clicked){
          setClicked(false)
        } else {
          setClicked(true)
        }
      }

      const [twitterLink, setTwitterLink] = useState("")
      const [igLink, setIgLink] = useState("")
      const [fbLink, setFbLink] = useState("")

      useEffect(() => {

          if(props.instagram && props.twitter){

            setTwitterLink("https://www.twitter.com/" + props.twitter)
            setIgLink("https://www.instagram.com/" + props.instagram)
          } else {

            setTwitterLink("https://www.twitter.com/" + "kujeera")
            setIgLink("https://www.instagram.com/" + "kujeera")

          }

    }, [props]);

      return (
        <div>

        <div className="icons">
        <div className="lefticons">
        <a href={twitterLink}>
        <span className="iconButton" style={{marginRight:20}} onClick={() => handleClick()}><ion-icon name="logo-twitter" style={size.width > 1000 ? {fontSize:30} : {fontSize:25}}></ion-icon></span>
        </a>
        <a href={igLink}>
        <span className="iconButton" onClick={() => handleClick()}><ion-icon name="logo-instagram" style={size.width > 1000 ? {fontSize:30} : {fontSize:25}}></ion-icon></span>
        </a>
        </div>
        {/*<p className="timingext" style={{fontSize:"1.2rem"}}>SOUL</p>*/}
        </div>

        </div>
      )
    }

export default IconBarSocial
