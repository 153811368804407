import { useState, useEffect, useRef } from 'react';

const UseInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback(() => {
    });
  }, [isFetching]);

  function handleScroll() {
    const currentScrollY = window.scrollY;
    if (prevScrollY.current > currentScrollY || isFetching) return;
    setIsFetching(true);
  }

  return [isFetching, setIsFetching];
};

export default UseInfiniteScroll;
