import React from "react"
import { useState, useEffect } from "react"

import { db } from '../services/firebase';

import UseWindowSize from "./UseWindowSize"

function Paradise(props){

    const size = UseWindowSize();

    /*

    const [destination, setDestination] = useState("")

    useEffect(() => {

        setDestination(props.article.location)

    }, [props]);

    */

    const [paradise, setParadise] = useState([]);

    useEffect(() => {
        if(!props.art){
        const subscriber = db
          .collection("paradise")
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setParadise(data);
          });
        } else {
          const subscriber = db
            .collection("artistinres")
            .orderBy('timestamp', 'desc')
            .limit(1)
            .get()
            .then(snap => {
              const data = snap.docs.map(doc => doc.data())
              setParadise(data);
            });
        }
    }, [props.database]);

      return (
        <div>
        {/*<section style={size.width > 1000 ? {} : {maxWidth: "60%"}}>*/}
        {/*<section>*/}
        <div className="paradiseHolder">
        {paradise[0] &&
        <a href={paradise[0].destination}>
        <p className="timingext paradiseLine" >{paradise[0].title.toUpperCase()}</p>
        </a>
        }
        </div>
        {/*</section>*/}
        </div>
      )

    /*

    return (
      <section>
      <div style={{backgroundColor: 'white', color:'black', textAlign:"center"}}>
      <a href={"https://www.soul.kujeera.com"}>
      {paradise[0] &&
      <p className="timingext" style={{backgroundColor: 'white', color:'black', textAlign:"center", fontWeight:"normal", border:"2px solid black"}}>{paradise[0].title.toUpperCase()}</p>
      }
      </a>
      </div>
      </section>
    )

    */

}

export default Paradise
