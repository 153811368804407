import React from "react"
import {useState, useEffect} from "react"

import UseWindowSize from "./UseWindowSize"

export default function AuthorContact(props){

    const [twitterLink, setTwitterLink] = useState("")
    const [igLink, setIgLink] = useState("")
    const [fbLink, setFbLink] = useState("")

    const size = UseWindowSize()

    useEffect(() => {

        setTwitterLink("https://www.twitter.com/" + props.author.twitter)
        if(props.author.instagram){
        setIgLink("https://www.instagram.com/" + props.author.instagram.replace('@', ''))
        }
        setFbLink("https://www.facebook.com/" + props.author.facebook)

  }, [props.author]);

  return (
    <div>
    <div className="icons">
    <div className="lefticons">
    <a href={twitterLink}>
    <span className="iconButton" style={{marginRight:20}}><ion-icon name="logo-twitter" style={size.width > 1000 ? {fontSize:30} : {fontSize:20}}></ion-icon></span>
    </a>
    <a href={igLink}>
    <span className="iconButton"><ion-icon name="logo-instagram" style={size.width > 1000 ? {fontSize:30} : {fontSize:20}}></ion-icon></span>
    </a>
    </div>

    </div>
    </div>
  )


}
